import StatusIndicator from "../StatusIndicator";
import * as React from "react";
import { alpha, styled } from "@mui/material/styles";
import { TreeItem, treeItemClasses, TreeItemProps } from "@mui/x-tree-view/TreeItem";
import { MouseEvent, useState } from 'react';
import TreeItemsGroup from "../TreeItemsGroup";

// const CustomTreeItem = React.forwardRef(
//   (props: TreeItemProps, ref: React.Ref<HTMLLIElement>) => {

//     return (
//       <TreeItem {...props} ref={ref} />
//     )
//   }
// );

const StyledTreeItem = styled(TreeItem)(
  ({ theme }) => ({
    [`& .${treeItemClasses.iconContainer}`]: {
      '& .close': {
        opacity: 0.3,
      },
    },
    [`& .${treeItemClasses.group}`]: {
      marginLeft: 15,
      paddingLeft: 18,
      fontSize: '1em',
      borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
  })
);

const MyTreeItem = (props: any) => {
  const [popStatus, setPopStatus] = useState(false);
  const thisNode = props.parent[props.value];

  function statusPopup(e: MouseEvent<HTMLLIElement>) {
    if (!thisNode.children) {
      setPopStatus(!popStatus);
    }
  }

  return (
    <StyledTreeItem
      className={popStatus ? "popup-expanded" : ""}
      nodeId={props.myid}
      id={props.myid}
      key={props.myid}
      onClick={statusPopup}
      label={<>
        <StatusIndicator data={{
          Title: props.value,
          Status: thisNode.status,
          disabled: (thisNode.isUsed === false), // only leaves have the isUsed property explicitly set to true|false
          anomaly: (thisNode.anomaly),
          plot: (thisNode.plot),
          popStatus: popStatus
        }} />
      </>}
    >
      {thisNode.children ?
        <TreeItemsGroup data={thisNode.children} path={props.myid} greenFilter={props.greenFilter} />
        :
        null
      }
    </StyledTreeItem>
  );
}

export default MyTreeItem;