import CircleIcon from '@mui/icons-material/Circle';
import { Box, SvgIconProps } from '@mui/material';


export default function HelpBox() {

  return (
    <Box sx={{
      color: "#666",
      fontSize: "0.9em",
      backgroundColor: "#f6f6f6",
      padding: "0.5em",
      marginTop: "1em",
      borderRadius: "5px"

    }} >
      This dashboard provides a summary of availability, quality and usage of observations received at ECMWF. Observations are grouped in categories to reflect the data type and observed geophysical parameters. The availability and quality status is provided by the ECMWF automatic data checking system. The status is displayed using a traffic light system:

      <p><SmallCircleIcon color="success" /> The product group is Nominal.</p>
      <p><SmallCircleIcon color="warning" /> The product group is degraded. Click on the group to reveal the sub-groups affected.</p>
      <p><SmallCircleIcon color="error" /> The product group is affected by severe anomalies or quality or availability. Click on the group to reveal the sub-groups affected.</p>
      <p><SmallCircleIcon color="disabled" /> The channel/layer is monitored only.  </p>
    </Box>
  )
}

function SmallCircleIcon(props: SvgIconProps) {
  return (
    <CircleIcon fontSize="small" sx={{ verticalAlign: "bottom" }} color={props.color} />
  )
}
