import CircleIcon from '@mui/icons-material/Circle';
import Chip from '@mui/material/Chip';
import { ledColor } from 'utility';

export default function StatusIndicator(props: any) {

  const color = props.data.disabled === true ? "disabled" : ledColor(props.data.Status)

  return (
    <>
      <Chip
        sx={{ marginBottom: "3px", marginTop: "3px" }}
        icon={<CircleIcon color={color} />}
        label={props.data.Title}
        variant="outlined"
        disabled={props.data.disabled}
      />

      {props.data.popStatus &&
        <>
          <p>{props.data.anomaly}</p>
          <p><a href={props.data.plot} target='_blank' rel="noreferrer">See plot</a></p>
        </>
      }
    </>
  )
}
