import { TreeView } from "@mui/x-tree-view/TreeView";
import React, { useEffect, useState } from "react";
import TreeItemsGroup from "./TreeItemsGroup";
import { FormControlLabel, Switch } from '@mui/material';
import { ObsNodeTree } from "../ObservationsRoot/types";
import { useSearchParams } from "react-router-dom";

export default function StatusCard(props: { data: ObsNodeTree; steps: string }) {
  const [greenFilter, setGreenFilter] = useState(true);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  function hideNoAnomalies(status: boolean) {

    setGreenFilter(status);
    setSearchParams((prevParams) => {
      return new URLSearchParams({
        ...Object.fromEntries(prevParams.entries()),
        ...{ hide_no_anomalies: status.toString() },
      });
    }, { replace: true });
  }

  useEffect(() => {
    setGreenFilter(searchParams.get('hide_no_anomalies') === "true");
  }, [searchParams]);

  return (
    <>
      {props.data.length > 0 ?
        <>
          <FormControlLabel control={
            <Switch checked={greenFilter} onChange={
              (e) => {
                hideNoAnomalies(e.target.checked);
              }
            } />
          } label="hide channels with no anomalies" />

          <TreeView
            expanded={expanded}
            disableSelection={true}
            onNodeToggle={(event, nodeIds) => {
              // close all items not in the current branch
              if (!nodeIds.length) {
                setExpanded([]);
              } else {
                const target = nodeIds[0]; // <-- first item is the clicked one? Unclear but it seems to work...
                const this_branch = target.split("_")[1];
                nodeIds = nodeIds.filter(
                  (nodeId) => (nodeId.split("_")[1] === this_branch)
                );
                setExpanded(nodeIds);
              }
            }
            }
          // defaultCollapseIcon={<MinusSquare />}
          // defaultExpandIcon={<PlusSquare />}
          // defaultEndIcon={<CloseSquare />}
          >
            {
              props.data.length > 0 ?
                <TreeItemsGroup data={props.data} greenFilter={greenFilter} path="root" />
                :
                <></>

            }
          </TreeView>
        </>
        :
        "data missing!"
      }

    </>
  );
}
