import { ObsNodeTree } from "components/ObservationsRoot/types";
import SETTINGS from 'configuration';

/**
 * Maps strings into the 3 "traffic light" color names used by CircleIcon component
 */

export function ledColor(status: string): "success" | "disabled" | "warning" | "error" {
  status = status.toLowerCase();
  if (status === "ok" || status === "0") {
    return "success"
  } else if (status === "degraded" || status === "1") {
    return "warning"
  } else if (status === "error" || status === "2") {
    return "error"
  } else {
    return "disabled"
  }
};

export function table2tree(results: any): ObsNodeTree {
  return results.data.reduce(
    function (newObj: any, item: any) {

      var label_1 = item.ObservationCategory.trim();
      if (newObj[label_1] === undefined) {
        newObj.push(label_1);
        newObj[label_1] = {
          "level": "1",
          "status": item.StatusObservationCategory.trim(),
          "children": []
        }
      }
      var thisLevel = newObj[label_1];

      var label_2 = item.ObservationType.trim();
      if (thisLevel.children[label_2] === undefined) {
        thisLevel.children.push(label_2)
        thisLevel.children[label_2] = {
          "level": "2",
          "status": item.StatusObservationType.trim(),
          "children": []
        }
      }

      thisLevel = thisLevel.children[label_2];
      var label_3 = item.Platform.trim();
      if (thisLevel.children[label_3] === undefined) {
        thisLevel.children.push(label_3)
        thisLevel.children[label_3] = {
          "level": "3",
          "status": item.StatusPlatform.trim(),
          "children": []
        }
      }

      thisLevel = thisLevel.children[label_3];
      var label_4 = item.Area.trim();
      if (thisLevel.children[label_4] === undefined) {
        thisLevel.children.push(label_4)
        thisLevel.children[label_4] = {
          "level": "4",
          "status": item.StatusArea.trim(),
          "children": []
        }
      }

      thisLevel = thisLevel.children[label_4];
      var label_5 = item.Channel.trim();
      if (thisLevel.children[label_5] === undefined) {
        thisLevel.children.push(label_5)
        thisLevel.children[label_5] = {
          "level": "5",
          "status": item.StatusChannel.trim(),
          "anomaly": item.Anomaly.trim(),
          "plot": item.Plot.trim(),
          "isUsed": item.Usage.trim() === "1",
        }
      }

      return newObj;
    },
    []
  );
}

function getUrlsFromToday() {
  const todayTimestamp = new Date().getTime();

  const baseDates: number[] = [];
  const allSteps: string[] = [];

  for (var i = 0; i < 7; i++) {
    baseDates.push(todayTimestamp - (1000 * 60 * 60 * 24 * i));
  }

  baseDates.forEach((item) => {
    const iso = new Date(item).toISOString();
    const base = iso.substring(0, 4) + iso.substring(5, 7) + iso.substring(8, 10);
    allSteps.push(base + "12");
    allSteps.push(base + "00");
  });

  return allSteps;
}

async function validateSteps(targetSteps: string[]): Promise<string[]> {
  const validUrls: string[] = []
  for (const item of targetSteps) {
    const response = await fetch(step2Url(item), {
      method: 'HEAD'
    });
    if (response.status === 200) {
      validUrls.push(item);
    }
  }
  return validUrls;
}

export function getValidSteps(): Promise<string[]> {
  return validateSteps(getUrlsFromToday());
}

export function step2Url(step: string): string {
  return SETTINGS.url.observations + "/" + step + "_data.csv";
}

export function step2Date(step: string): string {
  return step.substring(0, 4) + "/" + step.substring(4, 6) + "/" + step.substring(6, 8) + " step " + step.substring(8, 10) + ":00 UTC";
}