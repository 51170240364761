import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import StatusCard from '../StatusCard';
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { usePapaParse } from 'react-papaparse';
import { table2tree } from 'utility';
import { ObsNodeTree } from "components/ObservationsRoot/types";
import { Alert, Box, CircularProgress } from '@mui/material';
import { getValidSteps } from 'utility';
import { step2Url } from 'utility';
import BasetimeSelect from 'components/BasetimeSelect';
import HelpBox from 'components/HelpBox';
import { useSearchParams } from 'react-router-dom';

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

export default function ObservationsRoot() {
  const { readRemoteFile } = usePapaParse();
  const [loadedData, setLoadedData] = useState<ObsNodeTree | null>([]);
  const [currentStep, setCurrentStep] = useState<string>("");
  const [validSteps, setValidSteps] = useState<string[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  function changeCurrentStep(step: string) {
    setCurrentStep(step);
    setSearchParams((prevParams) => {
      return new URLSearchParams({
        ...Object.fromEntries(prevParams.entries()),
        ...{ step: step },
      });
    }, { replace: true });
    handleReadRemoteFile(step2Url(step));
  }

  const handleReadRemoteFile = (url: string) => {


    readRemoteFile(url, {
      download: true,
      header: true,
      skipEmptyLines: true,
      comments: "#",
      error: (results) => {
        // trigger when error on the request (NOT errors when parsing)
        console.log("SOMETHING BAD HAPPENED!");
        setLoadedData(null);
      },
      complete: (results) => {
        if (results.errors.length !== 0) {
          console.log("There are errors: ", results.errors);
          setLoadedData(null);
        } else {
          const observations = table2tree(results);
          setLoadedData(observations);
        }
      },
    });
  };

  useEffect(() => {
    getValidSteps()
      .then((res) => {
        setValidSteps(res);
        const stepFromURL = searchParams.get('step')
        if (stepFromURL !== null && res.includes(stepFromURL) === true) {
          changeCurrentStep(stepFromURL);
        } else if (res[0]) {
          changeCurrentStep(res[0]);
        } else {
          console.log("Something bad happened!");
        }

      });
  }, [])

  return (
    <StyledPaper elevation={0} >

      <Card sx={{
        minWidth: 200,
        width: 600,
        margin: "auto",
      }}>
        <CardHeader title="Observations Monitoring Dashboard" />

        {(loadedData !== null && loadedData.length > 0) &&
          <>
            <div><BasetimeSelect validSteps={validSteps} currentStep={currentStep} handleChange={changeCurrentStep} /></div>
            <StatusCard data={loadedData} steps={currentStep} />
          </>

        }{(loadedData !== null && loadedData.length === 0) &&
          <>
            <CircularProgress sx={{ display: "block", margin: "10px auto" }} />
          </>

        }{loadedData == null &&
          <Box>
            <Alert severity="error"><b>Error fetching data!</b>
              <p>
                There might be a temporary network issue that prevents displaying observations monitoring data.
              </p>
              <p>
                Try to reload this page. If the problem persists, try again later.
              </p>
            </Alert>
          </Box>
        }

        <HelpBox />

      </Card>

    </StyledPaper>
  )

}