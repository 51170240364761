
const PlaceholderMessage = (props: { count: Number }) => {

  return (
    <li style={{ display: "block", fontStyle: "italic" }}>
      <p>{props.count.toString()} channel(s) currently hidden because they present no anomalies.</p>
    </li>
  );
}

export default PlaceholderMessage;
