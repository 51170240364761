import { MenuItem, Select } from "@mui/material";
import { step2Date } from "utility";

export default function BasetimeSelect(props: any) {

  const isLatestStep = (props.currentStep === props.validSteps[0]);
  return (
    <>
      Showing data from <Select
        id="basetime-select"
        value={props.currentStep}
        onChange={(e) => { return props.handleChange(e.target.value) }}
      >

        {props.validSteps.map((e: string) => {
          return (
            <MenuItem key={e} value={e}>
              {e === props.validSteps[0] ? <strong>{step2Date(e)}&nbsp;(latest)</strong> : <>{step2Date(e)}</>}
            </MenuItem>
          )
        })
        }
      </Select>
    </>
  )
}