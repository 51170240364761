import PlaceholderMessage from "../PlaceholderMessage";
import MyTreeItem from "../MyTreeItem";

const TreeItemsGroup = (props: { data: any[]; greenFilter: boolean; path: string }) => {

  // if one of the children has no children, this is the last parent
  const isLastParent = !Object.hasOwn(props.data[props.data[0]], "children");

  // store original length to compare with final length
  const origLength = props.data.length;
  let output = (props.data.map((value, index, parent) => {

    // we may have items with the same name in different places.
    // by building a string with the index of all the parents' indexes
    // we can differenciate them.
    const myid = props.path + "_" + index;

    if (props.greenFilter && parent[value].status === "0" && isLastParent) {
      // early exit if there's nothing to render
      return null;
    }
    return (
      <MyTreeItem
        key={myid}
        myid={myid}
        index={index}
        value={value}
        parent={parent}
        greenFilter={props.greenFilter}
      />
    )
  })
    // remove null items
  ).filter((e) => e);

  if (props.greenFilter && isLastParent) {
    const hiddenItemsCount = (origLength - output.length);
    if (hiddenItemsCount > 0) {
      output = [<PlaceholderMessage key="placeholder" count={hiddenItemsCount} />, ...output];
    }
  }

  return (
    <>
      {output}
    </>
  );

}

export default TreeItemsGroup;
